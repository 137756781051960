<template>
  <v-container>
    <h1 class="text-left overview-title">
      <span v-html="$t('tsm1_overview_title')" />
    </h1>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-local-unit ref="search-local-unit" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-processing-form ref="search-processing-form" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-encashment-organisation ref="search-encashment-organisation" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('tsm1_overview_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('tsm1_overview_reset')" /></v-btn>
    <v-btn color="secondary" @click="download"><span v-html="$t('tsm1_overview_download')" /></v-btn>
    <v-btn
      color="secondary"
      @click="downloadRawmilkFunds"
      v-if="
        $privileges.has({
          path: '/processingForms/reports/rawmilkFunds',
          permission: 'read'
        })
      "
      ><span v-html="$t('tsm1_overview_download_rawmilk')"
    /></v-btn>
    <v-data-table-server
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      :multi-sort="false"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      density="compact"
    >
      <template v-slot:[`item.edit`]="{ item }">
        <template
          v-if="
            $privileges.has({
              path: '/processingForms',
              permission: 'write'
            }) ||
            $privileges.has({
              path: '/processingForms',
              permission: 'acceptErrors'
            })
          "
        >
          <router-link :to="{ name: 'processingForms_edit', params: { id: item.id } }" class="tableaction link">
            <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon></router-link
          >
        </template>
      </template>
      <template v-slot:[`item.dairyIdent`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.dairyId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.dairyIdent }}
          </router-link>
        </template>
        <template v-else>
          {{ item.dairyIdent }}
        </template>
      </template>
      <template v-slot:[`item.statusClosedAndNoValues`]="{ item }">
        <template v-if="item.statusClosedAndNoValues"> X </template>
      </template>
      <template v-slot:[`item.correction`]="{ item }">
        {{ item.correction === true ? $t('processing_form_correction_yes') : $t('processing_form_correction_no') }}
      </template>
      <template v-slot:[`item.statusIdent`]="{ item }">
        {{ item['status' + this.$getUpLangKey()] }}
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">{{ $moment(item.validFrom).format('DD.MM.YYYY') }}</template>
      <template v-slot:[`item.validUntil`]="{ item }">{{ $moment(item.validUntil).format('DD.MM.YYYY') }}</template>
    </v-data-table-server>
  </v-container>
</template>

<script lang="ts">
import { apiURL } from '@/main'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLocalUnit from '@/components/searchCards/searchLocalUnit.vue'
import { useSearchLocalUnitStore } from '@/store/SearchLocalUnitStore'
import searchProcessingForm from '@/components/searchCards/searchProcessingForm.vue'
import { useSearchProcessingFormStore } from '@/store/SearchProcessingFormStore'
import { useSearchOrganisationStore } from '@/store/SearchOrganisationStore'

import searchEncashmentOrganisation from '@/components/searchCards/searchSmpEncashmentOrganisation.vue'
import { Term } from '@/services/term'
import _ from 'lodash'
import { fileCreator, showError } from '@/services/axios'
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'overview',
  data() {
    return {
      items: [],
      totalElements: 0,
      loading: false,
      fields: <DTSHeader[]>[
        {
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // MBH-ID
        {
          title: this.$t('tsm1_overview_mbh_id'),
          key: 'dairyIdent',
          sortable: false,
          align: 'left'
        },
        // Name/Firma
        {
          title: this.$t('tsm1_overview_surname'),
          key: 'addressName1',
          sortable: false
        },
        // Vorname/Zusatz
        {
          title: this.$t('tsm1_overview_name'),
          key: 'addressAddress1',
          sortable: false
        },
        // Status
        {
          title: this.$t('tsm1_overview_status'),
          key: 'status' + this.$getUpLangKey(),
          sortable: false
        },
        //Periodizität
        {
          title: this.$t('tsm1_overview_table_header_periodicity'),
          key: 'formPeriod' + this.$getUpLangKey(),
          sortable: true
        },
        // Validierung
        {
          value: 'statusClosedAndNoValues',
          sortable: true,
          size: '2%'
        },
        //Von
        {
          title: this.$t('tsm1_overview_table_header_valid_from'),
          key: 'validFrom',
          sortable: true
        },
        //Bis
        {
          title: this.$t('tsm1_overview_table_header_valid_until'),
          key: 'validUntil',
          sortable: true
        },
        //Korrektur
        {
          title: this.$t('tsm1_overview_table_header_correction'),
          key: 'correction',
          sortable: true
        },
        //Zuständig
        {
          title: this.$t('tsm1_overview_table_header_responsible_employee'),
          key: 'dairyPersonInitials',
          sortable: false
        },
        //Gesuchs-ID
        {
          title: this.$t('tsm1_overview_table_header_ident'),
          key: 'ident',
          align: 'left',
          sortable: true
        },
        //Ablage-Nr.
        {
          title: this.$t('tsm1_overview_table_header_code'),
          key: 'code',
          sortable: true
        }
      ]
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchLocalUnitStore(), useSearchLocalUnitStore().items)
      ]
    },
    /* query for axios-calls and route-pushes */
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params(true),
        ...this.getJavaPageOptions({ sort: ['ident,asc'] })
      }
    },
    /* query for axios-calls and route-pushes */
    queryBackend() {
      return {
        term: Term.stringify(this.term),
        ...this.paramsBackend(true),
        ...this.getJavaPageOptions({ sort: ['ident,asc'] })
      }
    },
    /* query for axios-calls and route-pushes */
    queryRMFBackend() {
      return {
        term: Term.stringify(this.term),
        ...this.paramsBackend(false)
      }
    }
  },
  components: {
    searchDairy,
    searchContact,
    searchLocalUnit,
    searchProcessingForm,
    searchEncashmentOrganisation
  },
  methods: {
    params(groupByForm) {
      return {
        ProcessingForm_id: useSearchProcessingFormStore().ProcessingForm_id,
        ProcessingForm_ident: useSearchProcessingFormStore().ProcessingForm_ident,
        ProcessingForm_statusIdMin: useSearchProcessingFormStore().ProcessingForm_statusIdMin,
        ProcessingForm_statusIdMax: useSearchProcessingFormStore().ProcessingForm_statusIdMax,
        ProcessingForm_isCorrection: useSearchProcessingFormStore().ProcessingForm_isCorrection,
        ProcessingForm_validFrom: useSearchProcessingFormStore().ProcessingForm_validFrom,
        ProcessingForm_validUntil: useSearchProcessingFormStore().ProcessingForm_validUntil,
        ProcessingForm_periodId: useSearchProcessingFormStore().ProcessingForm_periodId,
        EncashmentOrganisation_id: useSearchOrganisationStore().EncashmentOrganisation_id,
        ProcessingForm_code: useSearchProcessingFormStore().ProcessingForm_code,
        groupByForm: groupByForm
      }
    },
    paramsBackend(groupByForm) {
      return {
        processingFormId: useSearchProcessingFormStore().ProcessingForm_id,
        processingFormIdent: useSearchProcessingFormStore().ProcessingForm_ident,
        processingFormStatusIdMin: useSearchProcessingFormStore().ProcessingForm_statusIdMin,
        processingFormStatusIdMax: useSearchProcessingFormStore().ProcessingForm_statusIdMax,
        processingFormIsCorrection: useSearchProcessingFormStore().ProcessingForm_isCorrection,
        processingFormValidUntilSearchRangeMin: useSearchProcessingFormStore().ProcessingForm_validFrom,
        processingFormValidUntilSearchRangeMax: useSearchProcessingFormStore().ProcessingForm_validUntil,
        processingFormPeriodId: useSearchProcessingFormStore().ProcessingForm_periodId,
        encashmentOrganisationId: useSearchOrganisationStore().EncashmentOrganisation_id,
        processingFormCode: useSearchProcessingFormStore().ProcessingForm_code,
        groupByForm: groupByForm
      }
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/processingForms/reports/overview/', {
          params: this.queryBackend
        })
        const data = await response.data
        this.items = Array.isArray(data.content) ? data.content : []
        this.totalElements = data.totalElements
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    reset() {
      this.$refs['search-dairy'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-local-unit'].reset()
      this.$refs['search-processing-form'].reset()
      this.$refs['search-encashment-organisation'].reset()
    },
    async download() {
      try {
        const response = await this.axios.get(apiURL + '/processingForms/reports/overview/', {
          params: this.queryBackend,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'overview.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      }
    },
    async downloadRawmilkFunds() {
      try {
        const response = await this.axios.get(apiURL + '/processingForms/reports/rawmilkFunds/', {
          params: this.queryRMFBackend,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'rawmilkFunds.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      }
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initialized = true
      if (this.$route.query.size) this.load()
    })
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.overview-title {
  margin-bottom: $spacer-xs !important;
}

.button-margin {
  margin-right: $spacer-xs !important;
}
</style>
